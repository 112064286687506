import request from "@/services/request"
import baseUrl from "@/services/baseUrl"

let servicePath = "/ji036/v1/academicYr";
let servicePathCycles = "/ji036/v1/cycles";
const mpBaseUrl = baseUrl.main + servicePath;
const mpBaseUrlCycles = baseUrl.main + servicePathCycles;

export default {
    getAllAcademicYears: (params={}) => request.post(mpBaseUrl + "/listAY", params),
    getCurrentYr: (params={}) => request.post(mpBaseUrl + "/current", params),
    getCycles: (params={}) => request.post(mpBaseUrlCycles + "/all", params),
    getAll: (params={}) => request.post(mpBaseUrl + "/get", params),
    createAcademicYear: (params={}) => request.post(mpBaseUrl + "/new", params), 
    update: (params={}) => request.put(mpBaseUrl + "/update", params),
}