<template>
  <div class="px-lg-5 ">
    <div>
      <b-alert v-model="errorBasicOD" dismissible variant="danger">
        <div class="alert-body">
          Select a re-opening date
        </div>
      </b-alert>
      <b-alert v-model="errorBasicCD" dismissible variant="danger">
        <div class="alert-body">
          Select a closing date
        </div>
      </b-alert>
      <!-- <b-alert v-model="errorBasicGeneralTuition" v-height-fade.appear dismissible variant="danger">
        <div class="alert-body">
          Please enter the tuition for the academic year
        </div>
      </b-alert> -->
      <b-alert v-model="errorNoCycleSelected" v-height-fade.appear dismissible variant="danger">
        <div class="alert-body">
          Please select at least one school cycle
        </div>
      </b-alert>
      <b-alert v-model="isClassSavedError" v-height-fade.appear dismissible variant="danger">
        <div class="alert-body">
          Please save your changes
        </div>
      </b-alert>
    </div>
    <form-wizard
      class="p-4 wizard-vertical"
      layout="vertical"
      title=""
      subtitle=""
      color="#909"
      finishButtonText="Create"
      @on-complete="newAcademicYear"
    >
      <tab-content title="Basic Info" :before-change="validateBasicInfo">
        <blockquote class="blockquote text-center">
          <p class="mb-0">Welcome, Let us create a new academic year.</p>
          <footer class="blockquote-footer">
            {{ storeDate.month }} {{ storeDate.currentYear }}
            <span class="small">/</span>
            {{ storeDate.nextYear }}
          </footer>
        </blockquote>
        <div class="d-flex justify-content-center pb-1">
          <div class="text-center p-3 m-2 welcome-img">
            <feather-icon size="56" icon="BookOpenIcon" />
          </div>
        </div>
        <div class="text-center pb-4">
          <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              <feather-icon icon="RefreshCCWIcon" class="mr-50" />
              <span class="align-middle">Load Previous Year</span>
            </b-button> -->
        </div>
        <div class="w-75 mx-auto pb-4">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Re-opening Date" label-for="input-date">
                <flat-pickr
                  v-model="state.selectedDate"
                  class="form-control form-control-md"
                  :class="isOpeningDateValid"
                  :config="datePickerConfig"
                  name="input-date"
                  @on-close="formatDate('open')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Closing Date" label-for="input-date">
                <flat-pickr
                  v-model="state.closingDate"
                  class="form-control form-control-md"
                  :class="isClosingDateValid"
                  :config="datePickerConfig"
                  name="input-date"
                  @on-close="formatDate('close')"
                /> </b-form-group
            ></b-col>
            <!-- <b-col cols="12">
              <b-form-group label="School Fees" label-for="input-fees">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="state.generalTuition"
                    id="input-fees"
                    placeholder=""
                  />
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="showHelpToolTip">
                      <feather-icon icon="InfoIcon"  class="mr-50"/>
                      <span>Info</span>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-alert v-model="helpTuitionToolTip" dismissible variant="primary">
                <div class="alert-body">
                  This will set the general fees for the academic year. Click next to customise the fees for each class.
                </div>
              </b-alert>
            </b-col> -->
          </b-row>
        </div>
      </tab-content>
      <tab-content title="Select Cycles" :before-change="validateCycleSelect">
        <h4 class="pb-2 text-center">Select Academic Cycles</h4>
        <b-form-group class="larger-font">
          <b-row>
            <b-col cols="6" v-for="(item, index) in schoolCycle" :key="index">
              <div v-if="item.cycleCode < 700">
                <b-form-checkbox
                  switch
                  size="md"
                  v-model="state.selectedCycle"
                  :value="item"
                >
                  {{ item.displayName }}
                </b-form-checkbox>
              </div>

              <!-- Custom cycles input  -->
              <div v-else>
                <b-input-group v-if="item.editable">
                  <b-input-group-prepend is-text>
                    <b-form-checkbox
                      name="checkbox-input"
                      switch
                      v-model="state.selectedCycle"
                      :value="item"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    :class="validInputRequired(item.displayName)"
                    placeholder="Custom label"
                    autofocus
                    v-model="item.displayName"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-success"
                      @click="saveCustomCycleLabel(index, item.displayName)"
                    >
                      <feather-icon icon="SaveIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <div
                  v-else
                  class="
                    d-flex
                    justify-content-start
                    align-items-center align-content-center
                  "
                >
                  <b-form-checkbox
                    switch
                    size="md"
                    v-model="state.selectedCycle"
                    :value="item"
                  >
                    {{ item.displayName }}
                  </b-form-checkbox>
                  <b-button-group class="ml-4" style="margin-bottom: 2px">
                    <b-button
                      @click="editCustomCycleLabel(index)"
                      variant="flat-secondary"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      @click="deleteCustomCycleLabel(item.cycleCode, index)"
                      variant="flat-danger"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-button-group>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-primary"
                @click="addNewCycle()"
                class="w-50"
              >
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="align-middle">Custom Cycle</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </tab-content>
      <!-- Loop through the next sequence of tabs for each cycle -->
      <tab-content
        v-for="(item, index) in selectedSchoolCycle"
        :key="index"
        :title="item.displayName"
        :before-change="validClassDetails"
      >
        <div
          class="
            w-75
            mx-auto
            mb-2
            d-flex
            align-content-center
            justify-content-between
            align-items-center
          "
        >
          <h4 class="text-bold">Classes for {{ item.displayName }}</h4>
          <b-button
            variant="flat-primary"
            @click="addNewClass(item.cycleCode)"
            v-if="!onEditCycle"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Class</span>
          </b-button>
          <b-button variant="flat-light" @click="editLocalCycles(item)" v-else>
            <feather-icon icon="EditIcon" class="mr-50" />
            <span class="align-middle">Edit</span>
          </b-button>
        </div>
        <div class="w-75 mx-auto">
          <!-- Display the classes. There are 2 modes. Edit class details mode and display mode -->
          <!-- <b-row v-if="editClassNames"> -->
          <b-list-group flush v-if="onEditCycle">
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              v-for="(classes, index2) in item.classes"
              :key="index2"
            >
              <span>{{ classes.label }}</span>
              <span>{{ classes.tuition }}</span>
            </b-list-group-item>
          </b-list-group>

          <!-- Edit class mode begins here -->
          <div v-else class="mt-2">
            <b-row class="border-bottom mb-3">
              <b-col cols="12">
                <div class="form-label-group">
                  <b-form-input
                    :id="'cycle'+ '_' + index"
                    :placeholder="item.displayName + ' Tuition'"
                    v-model="item.tuition"
                  />
                  <label :for="'cycle'+ '_' + index">Tuition for {{ item.name }}</label>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-for="(classes, index2) in item.classes"
              :key="index2"
              class="mb-1"
            >
              <b-col md="4">
                <div class="form-label-group">
                  <b-form-input
                    :id="classes.name + ' ' + index"
                    placeholder="Class label"
                    v-model="classes.label"
                  />
                  <label :for="classes.name + ' ' + index">Class label</label>
                </div>
              </b-col>
              <b-col md="8">
                <div class="form-label-group">
                  <b-form-input
                    placeholder="Class tuition"
                    v-model="classes.tuition"
                  />
                  <label for="floating-label-disable">Class tuition</label>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="text-center">
                  <b-button
                    variant="flat-success"
                    @click="syncLocalCycles(item)"
                    style="min-width: 200px"
                  >
                    <feather-icon icon="SaveIcon" class="mr-50" />
                    <span class="align-middle">Save</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <span style="position: absolute; left: 60%; top: 40%">
          <b-spinner
            style="width: 40px; height: 40px"
            class="mr-1"
            label=""
            variant="primary"
            v-show="saveClass"
          />
        </span>
      </tab-content>
      <tab-content title="Transport Services"></tab-content>
      <tab-content title="Preview">
        <div v-if="!showCreationProgress" class="larger-font">
          <h4>
            Creating Academic Year {{ storeDate.currentYear }}/{{
              storeDate.nextYear
            }}
            - Preview
          </h4>

          <app-collapse>
            <app-collapse-item
              v-for="(item, index) in exportedCycle"
              :key="index"
              :title="item.displayName"
            >
              <div class="d-flex flex-column justifiy-content-center">
                <div>Tuition: {{ item.tuition }}</div>
                <div>Number of classes: {{ item.classes.length }}</div>
                <div v-for="(classItem, index2) in item.classes" :key="index2">
                  <span v-if="classItem.tuition"
                    >{{ classItem.label }}: {{ classItem.tuition }}</span
                  >
                </div>
              </div>
            </app-collapse-item>
          </app-collapse>
        </div>
        <div
          v-else
          class="
            w-100
            d-flex
            align-items-center
            justify-content-center
            flex-column
          "
          style="height: 400px"
        >
          <div>
            <b-spinner
              style="width: 64px; height: 64px"
              class="mr-1"
              label=""
              variant="primary"
              v-show="saveProgress"
            />
            <feather-icon
              style="color: green"
              size="64"
              v-if="isSaveSuccess && !saveProgress"
              icon="CheckCircleIcon"
            />
            <!-- <feather-icon style="color: red" size="64" v-if="isSaveSuccess && !saveProgress" icon="XOctagonIcon" /> -->
          </div>
          <span class="mt-2" style="font-size: 24px">
            {{ progressText }}
          </span>
        </div>
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { BAlert, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { heightFade } from "@core/directives/animations";
import { useRouter } from "@/@core/utils/utils";
import API from "./api";

export default defineComponent({
  components: {
    vSelect,
    flatPickr,
    FormWizard,
    TabContent,
    WizardStep,
    AppCollapse,
    AppCollapseItem,
    BAlert,
    BButton,
  },
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  props: {
    defaultCycle: {
      type: Array,
      required: true,
    },
  },
  emits: ["hideModal"],
  setup(props) {
    const { router } = useRouter();
    const saveClass = ref(false); // Loader when saving classes for the cycle
    const exportedCycle = ref([]); // Syncing this with the selectedCycles given it is a computed variable
    const onEditCycle = ref(false); // For toggling between saving the classes
    const showCreationProgress = ref(false); // Toggle tab container preview with loader when saving
    const saveProgress = ref(false); // Loader when saving academic year
    const isSaveSuccess = ref(false); // Icon when academic is saved
    const progressText = ref(""); // Text to show when saving academic Year
    const datePickerConfig = reactive({
      mode: "single",
      wrap: true,
      dateFormat: "l, j F Y",
    });
    // For the date picker starting A.Y.
    const storeDate = reactive({
      day: null,
      month: "September",
      currentYear: new Date().getFullYear(),
      nextYear: new Date().getFullYear() + 1,
    });

    // Date picker closing AY
    const endDate = reactive({
      day: null,
      month: "June",
      currentYear: new Date().getFullYear() + 1,
      nextYear: new Date().getFullYear() + 2,
    });
    // The form variables
    const state = ref({
      selectedDate: "",
      closingDate: "",
      generalTuition: null, // From backend
      selectedCycle: [],
      cycle: props.defaultCycle || [],
    });

    // console.log(props.defaultCycle)

    //Errors for the different
    const errorBasicOD = ref(false);
    const errorBasicCD = ref(false);
    const errorBasicGeneralTuition = ref(false);
    const helpTuitionToolTip = ref(false);
    const errorNoCycleSelected = ref(false)
    const isClassSavedError = ref(false)

    // Validate the the tabs before proceeding to the next
    const validateBasicInfo = () => {
      if (
        state.value.selectedDate &&
        state.value.closingDate 
      ) {
        return true;
      }
      if (!state.value.selectedDate) {
        errorBasicOD.value = true;
      }
      if (!state.value.closingDate) {
        errorBasicCD.value = true;
      }
      // if (!state.value.generalTuition) {
      //   errorBasicGeneralTuition.value = true;
      // }
      
      return false; // TODO: Set this back to false
    };

    const validateCycleSelect = () => {
      if (!state.value.selectedCycle.length) {
        errorNoCycleSelected.value = true
        return false
      }
      return true
    }

    const validClassDetails = () => {
      if (!onEditCycle.value) {
        isClassSavedError.value = true
        return false
      }
      return true;
    }

    const showHelpToolTip = () => {
      helpTuitionToolTip.value = true
    }

    // Get names used to describe classes
    const schoolClassEngine = (params) => {
      let classObj = {
        name: params.index, // name of the class (will be a num)
        tuition: "", // Tuition for that specific class
        cycleCode: params.cycleCode,
        label: params.label,
      };

      return classObj;
    };

    // Get names used to describe classes
    const getClassProperties = (params) => {
      let results = [];

      // Pre-nursery
      if (params.cycleCode === 601) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      // Nursery
      if (params.cycleCode === 602) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      // Primary
      if (params.cycleCode === 603) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      // Secondary
      if (params.cycleCode === 604) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      // Highschool
      if (params.cycleCode === 605) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      // Undergraduate
      if (params.cycleCode === 606) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      // Graduate
      if (params.cycleCode === 607) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      // Postgraduate
      if (params.cycleCode === 608) {
        for (let j = 0; j < params.numberOfClasses; j++) {
          results.push(
            schoolClassEngine({
              index: j + 1,
              cycleCode: params.cycleCode,
              label: params.classLabel + " " + (j + 1),
            })
          );
        }
      }

      return results;
    };

    // Create new academic year
    const newAcademicYear = async () => {
      showCreationProgress.value = true;
      saveProgress.value = true;
      progressText.value = "Loading...";

      let params = {
        startDay: storeDate.day,
        startMonth: storeDate.month,
        startYear: storeDate.currentYear,
        endDay: endDate.day,
        endMonth: endDate.month,
        endYear: storeDate.nextYear,
        // scopeId: "1234default", // TODO: Update to reflect the AppId of miniprogram (Getting it in the headers)
        class: [],
      };

      for (let i = 0; i < state.value.selectedCycle.length; i++) {
        const cycle = state.value.selectedCycle[i];

        for (let j = 0; j < cycle.classes.length; j++) {
          const classArray = cycle.classes[j];
          params.class.push({
            name: classArray.name,
            tuition: classArray.tuition || cycle.tuition,
            cycle: cycle._id,
            classLabel: classArray.label,
          });
        }
      }
      const { data, success } = await API.createAcademicYear(params);

      if (success) {
        // console.log(data);
        saveProgress.value = false;
        isSaveSuccess.value = true;
        progressText.value = "Successfully saved!";
        router.go(-1);
        showCreationProgress.value = false;
      }

      if (!success && !data) {
        saveProgress.value = false;
        isSaveSuccess.value = false;
        progressText.value = "Operation failed!";
      }
    };

    const formatDate = (value) => {
      // Extract the day, month and year to store in in BE
      setTimeout(() => {
        // 1-Opening day, 2-Closing day
        if (value === "open") {
          if (
            !state.value.selectedDate ||
            state.value.selectedDate.length < 1
          ) {
            return;
          }
          let formDate = state.value.selectedDate.split(" ");

          let day = parseInt(formDate[1].split(",")[0]);
          let month = formDate[2];
          let year = parseInt(formDate[3]);

          if (year < storeDate.currentYear) {
            return;
          }

          storeDate.day = day;
          storeDate.month = month;
          // console.log(JSON.stringify(storeDate, undefined, 2))
        }
        if (value === "close") {
          if (!state.value.closingDate || state.value.closingDate.length < 1) {
            return;
          }
          let formDate = state.value.closingDate.split(" ");

          let day = parseInt(formDate[1].split(",")[0]);
          let month = formDate[2];
          let year = parseInt(formDate[3]);

          // if (year+1 !== endDate.currentYear) {
          //   return;
          // }

          endDate.day = day;
          endDate.month = month;
          // console.log(JSON.stringify(endDate, undefined, 2))
        }
      }, 2000);
    };

    const addNewClass = (cycleCode) => {
      let cycleIndex = state.value.cycle.findIndex(
        (i) => i.cycleCode === cycleCode
      );

      let selectedCycleIndex = state.value.selectedCycle.findIndex(
        (i) => i.cycleCode === cycleCode
      );

      let classObj = {
        name: state.value.cycle[cycleIndex].numberOfClasses + 1, // the name of the class BE
        tuition: state.value.cycle[cycleIndex].tuition, // Tuition for that class
        cycleCode: cycleCode, // Code for cycle BE
        editable: true,
      };
      // if (cycleCode >= 700) {
      //   state.value.selectedCycle[selectedCycleIndex].numberOfClasses += 1;
      // }
      state.value.cycle[cycleIndex].numberOfClasses += 1;

      state.value.cycle[cycleIndex].classes.push(classObj);
      // console.log(
      //   JSON.stringify(
      //     state.value.selectedCycle[selectedCycleIndex],
      //     undefined,
      //     2
      //   )
      // );
    };

    const addNewCycle = () => {
      // fieldType.push({ value: "" });
      const arrayLen = state.value.cycle.length;
      let initCustomIndex = 700;
      let newIndex = initCustomIndex + arrayLen;

      let newCycle = {
        displayName: "", // The display name of the cycle
        name: "", // the name of the cycle BE
        tuition: null, // Tuition for that cycle
        cycleCode: newIndex, // Code for cycle BE
        classes: [],
        editable: true,
        classLabel: "Year",
        numberOfClasses: 0,
        status: 2, // This means it is custom made
      };

      newCycle.name = newCycle.displayName.toLowerCase();

      state.value.cycle.push(newCycle);
      state.value.selectedCycle.push(newCycle);

      addNewClass(newIndex)
      // console.log(JSON.stringify(state.value.cycle, undefined, 2))
    };

    // Edit label of custom cycle
    const editCustomCycleLabel = (index) => {
      state.value.cycle[index].editable = true;
    };

    // Delete custom cycle
    const deleteCustomCycleLabel = (value, index) => {
      let tabIndex = state.value.selectedCycle.findIndex(
        (i) => i.cycleCode === value
      );
      state.value.cycle.splice(index, 1);
      state.value.selectedCycle.splice(tabIndex, 1);
    };

    // Check if custom label of cycle is okay
    const validInputRequired = (params) => {
      if (params.length < 3 || !params) {
        return "is-invalid";
      }

      return "is-valid";
    };

    // Save label of custom cycle
    const saveCustomCycleLabel = (index, label) => {
      // state.value[index].displayName = label;
      if (validInputRequired(label) === "is-valid") {
        state.value.cycle[index].editable = false;
      }
    };

    // Process the UI for dynamic display of the the cycles in order of class promotion
    const schoolCycle = computed(() => {
      for (let i = 0; i < state.value.cycle.length; i++) {
        const cyc = state.value.cycle[i];
        cyc.tuition = state.value.generalTuition;
        cyc.classes = getClassProperties(cyc);
      }
      return state.value.cycle.sort((a, b) => a.cycleCode - b.cycleCode);
    });
    const selectedSchoolCycle = computed(() =>  state.value.selectedCycle.sort((a, b) => a.cycleCode - b.cycleCode));

    const isOpeningDateValid = computed(() => {
      if (state.value.selectedDate) {
        let formDate = state.value.selectedDate.split(" ");
        let year = parseInt(formDate[3]);

        if (year < storeDate.currentYear) {
          return "is-invalid";
        }
        return "is-valid";
      }
      return "";
    });

    const isClosingDateValid = computed(() => {
      if (state.value.closingDate) {
        let formDate = state.value.closingDate.split(" ");
        let year = parseInt(formDate[3]) + 1;

        if (year < endDate.nextYear) {
          return "is-invalid";
        }
        return "is-valid";
      }
      return "";
    });

    // Sync the selected array of cycles with the general one
    const syncLocalCycles = (params) => {
      saveClass.value = true;
      let cycleIndex = exportedCycle.value.findIndex(
        (i) => i.cycleCode === params.cycleCode
      );
      // making sure just one entry is in the array at a time
      if (cycleIndex === -1) {
        exportedCycle.value.push(params);
      } else {
        exportedCycle.value.splice(cycleIndex, 1, params);
      }

      setTimeout(() => {
        onEditCycle.value = true;
        saveClass.value = false;
      }, 500);

      console.log(JSON.stringify(exportedCycle.value, undefined, 2))
    };

    const editLocalCycles = () => {
      onEditCycle.value = false;
    };

    router.beforeEach((to, from, next) => {
      if (to.name === 'apps-academic-year-form') {
        console.log("Testing routing okay--->", from.name)
      }
    })

    onMounted(() => {
      // getDefaultCycles();
    });

    return {
      showCreationProgress,
      saveProgress,
      isSaveSuccess,
      progressText,
      state,
      storeDate,
      datePickerConfig,
      exportedCycle,
      newAcademicYear,
      formatDate,
      addNewCycle,
      addNewClass,
      editCustomCycleLabel,
      deleteCustomCycleLabel,
      saveCustomCycleLabel,
      validInputRequired,
      syncLocalCycles,
      editLocalCycles,
      schoolCycle,
      selectedSchoolCycle,
      isOpeningDateValid,
      isClosingDateValid,
      onEditCycle,
      saveClass,
      validateBasicInfo,
      validateCycleSelect,
      validClassDetails,
      errorBasicOD,
      errorBasicCD,
      errorBasicGeneralTuition,
      showHelpToolTip,
      helpTuitionToolTip,
      errorNoCycleSelected,
      isClassSavedError
    };
  },
});
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";

.welcome-img {
  border: 1px solid rgba($color: #ccc, $alpha: 0.6);
  color: rgba($color: $primary, $alpha: 1);
  // background-color: rgba($color: $primary, $alpha: 1.0);
  display: inline-block;
  margin: auto;
  border-radius: 10%;
}

.vertical.wizard-vertical.vue-form-wizard .wizard-tab-content {
  min-height: 476px;
}

.larger-font .custom-control-label {
  font-size: 1.1rem;
}

/**
 * ----------------------------------------
 * animation slide-top for tab updates
 * ----------------------------------------
 */
.slide-top {
  -webkit-animation: slide-top 0.3s ease-in both;
  animation: slide-top 0.3s ease-in both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
</style>